<template>
    <div class="navbottom c-center" :class="{navbox: ctrl}" :style="{visibility: ctrl ? 'visible' : 'hidden'}">
        <img height="120px"  src="../assets/img/arrow.gif" v-show="ctrl" alt="">
        <div class="btnlist bg" :class="{show}">
            <div v-for="item in navData" :key="item.id" @click="goto(item.routeName)">
                <div class="icon c-center" :class="[$route.path === item.routeName ? 'ibg2' : 'ibg']">
                    <img  :src="$route.path === item.routeName ? icon0 : item.icon">
                </div>
                <div class="btn-name">{{ item.moduleName }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import icon1 from "../assets/img/bom_home.png"
import icon2 from "../assets/img/bom_list1.png"
import icon3 from "../assets/img/bom_list2.png"
import icon4 from "../assets/img/bom_manage.png"
import icon5 from "../assets/img/bom_fenxi.png"
import icon6 from "../assets/img/bom_baojin.png"
import icon7 from "../assets/img/bom_quanxina.png"
import icon8 from "../assets/img/bom_log.png"
import icon9 from "../assets/img/bom_path.png"
import icon0 from "../assets/img/bom_home_2.png"
import axios from "../request"

export default {
    name: "NavBottom",
    props: {
        ctrl: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            show: false,
            navData: [],
            icon0,
            icons: {
                "/": icon1,//首页
                "/slimhome": icon1,//极简首页
                "/washroomlist": icon2,//公厕列表
                "/stationlist": icon3,//转运站列表
                "/archives": icon4,//档案管理
                "/statistics": icon5,//统计分析
                "/alarmrecord": icon6,//报警记录
                "/Permission": icon7,//权限管理
                "/log": icon8,//操作日志
                "/pathview": icon9, //轨迹查看
            }
        }
    },
    methods: {
        open(){
            this.show = !this.show;
        },
        goto(path){
            if(this.$router.history.current.fullPath=='/slimhome'){
                 this.$router.replace(path);
                this.open()
            }
            if(path!='/'){
                this.$router.replace(path);
                this.open()
            }
        }
    },
    async created(){
        let id = localStorage.getItem("id") ?? sessionStorage.getItem("id");
        let data = await axios.get("/menu/findRole/" + id);
        if(data){
            this.navData = data.map(item => {
                item.icon = this.icons[item.routeName]
                return item;
            });
            // console.log(this.navData)
        }
    },
    mounted(){
        this.$eventbus.$on('open', this.open)
    },
    beforeDestroy(){
        this.$eventbus.$off('open', this.open)
    }
};
</script>

<style scoped lang="scss">
.navbottom{
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 20%;
    overflow: hidden;
}
.navbox:hover .btnlist{
    bottom: 0px;
}
.navbox:hover > img{
    display: none;
}
.btnlist{
    width: 100%;
    padding-bottom: 30px;
    position: absolute;
    bottom: -100%;
    transition: all .5s ease;
    display: flex;
    justify-content: center;
    > div{
        margin: 0 calc(10 / 1920 * 100vw);
        text-align: center;
        cursor: pointer;
    }
    .icon{
        width: calc(50 / 1920 * 100vw);
        height: calc(50 / 1920 * 100vw);
        
        border: solid 2px $blue;
        border-radius: 5px;
        margin: 0 auto;
    }
    .ibg{
        background-color: white;
    }
    .ibg2{
        background-color: $blue;
    }
    .btn-name{
        margin-top: 5px;
        color: white;
    }
}
.bg{
    background: url("../assets/img/navbg.png") center bottom/100% 75px no-repeat;
    // background-image: url("../assets/img/navbg.png");
    // background-position: center bottom;
    // background-repeat: no-repeat;
    // background-size: 1920px 75px;
}
.show{
    visibility: visible;
    // display: none;
    bottom: 0px;
}

</style>
